<template>
  <div>
    <QrCodeScanComponent @scan-result="onDecode" />
    <div style="margin:10px">
      SCAN RESULT: {{ result }}
    </div>
  </div>
</template>
<script>
import QrCodeScanComponent from '../../components/QrCodeScanComponent'
export default {
  name: 'MainPage',
  components: {
    QrCodeScanComponent
  },
  data: function () {
    return {
      result: ''
    }
  },
  created () {
    this.userInfo = this.$store.getters.getUserInfo
    if (this.userInfo === undefined || this.userInfo === null) {
      this.$router.push('/')
    }
  },
  mounted () {

  },
  updated () {
  },
  methods: {
    onDecode (result) {
      this.result = result
    }
  }
}
</script>

<style lang="scss">
</style>
